@import url('https://fonts.googleapis.com/css?family=Orbitron&display=swap');

.App {
  font-family: 'Orbitron', sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin: 0;
  padding: 0;
  position: relative;
  /* Set a higher zIndex for the text container */
  z-index: 1;
}

h1 {
  font-size: 4rem;
}

.text-info {
  color: #ff69b4;
}

.text-info:hover {
  color: #ff94c2;
}

.email-link {
  font-family: 'Orbitron', sans-serif;
}